<template>
    <field-row
        :title="title"
        :tooltip="tooltip"
        :line-number="lineNumber"
        :hide-line-number="hideLineNumber"
    >
        <v-radio-group
            :value="$attrs.value"
            @change="onRadioValueChange"
            row
            dense
        >
            <v-radio v-for="({value, label}) of items"
                     :key="value"
                     :value="value"
                     :label="label"
            />
        </v-radio-group>
    </field-row>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
export default {
    name: "RadioGroupRow",
    components: {
        FieldRow,
    },
    props: {
        lineNumber: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
        },
        inputLabel: {
            type: String,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        hideLineNumber: {
            type: Boolean,
            required: false,
        }
    },
    methods: {
        onRadioValueChange(newValue) {
            this.$emit('input', newValue);
        }
    }
}
</script>

<style scoped>

</style>
