var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "resources-page" } },
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: " OAT Resources" },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-3", attrs: { width: "100%" } },
            [
              _c(
                "v-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { md: "8" } },
                    [
                      _c("store-search-new", {
                        attrs: {
                          airport: _vm.airport,
                          waypoint: _vm.waypointId,
                        },
                        on: {
                          "airport-change": function ($event) {
                            _vm.airport = $event
                          },
                          "waypoint-change": _vm.onLocationChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-0 mt-5", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-3 pl-1", attrs: { md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          attach: "",
                          label: "Search Resources",
                          clearable: "",
                        },
                        on: { change: _vm.debounced_searchChange },
                        model: {
                          value: _vm.searchString,
                          callback: function ($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isWaypointsSelected
                ? _c(
                    "v-row",
                    { staticClass: "pt-0 mt-0", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-3 pl-1", attrs: { md: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.languageOptions,
                              "item-value": "id",
                              "item-text": "value",
                              attach: "",
                              label: "Language Code",
                            },
                            on: { change: _vm.languageChange },
                            model: {
                              value: _vm.languageCode,
                              callback: function ($$v) {
                                _vm.languageCode = $$v
                              },
                              expression: "languageCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "fill-height" },
                [
                  _vm.mismatchedText.length > 0
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-1 pb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-banner",
                                {
                                  attrs: {
                                    color: "yellow darken-1 text--gray",
                                    rounded: "",
                                    outlined: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "yellow darken-2" } },
                                    [_vm._v("mdi-alert-outline ")]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.mismatchedText)),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "overflow-y-auto mt-2",
                      attrs: { "no-gutters": "", justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "5" } },
                            [
                              !_vm.isWaypointsSelected
                                ? _c("v-card-title", [
                                    _c("h5", { staticClass: "pt-3" }, [
                                      _vm._v(" Select a waypoint to start "),
                                    ]),
                                  ])
                                : !_vm.loading
                                ? _c(
                                    "v-card-text",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("ResourceForm", {
                                        attrs: {
                                          loading: _vm.loading,
                                          mismatched: _vm.mismatched,
                                          fields: _vm.filteredFields,
                                        },
                                        model: {
                                          value:
                                            _vm.stageResources[
                                              _vm.languageCode
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stageResources,
                                              _vm.languageCode,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "stageResources[languageCode]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-card-text",
                                    { staticClass: "pa-2" },
                                    _vm._l(20, function (i) {
                                      return _c("v-skeleton-loader", {
                                        key: i,
                                        staticClass: "ma-2 d-flex flex-column",
                                        attrs: {
                                          type: "list-item",
                                          elevation: "2",
                                          width: "100%",
                                          height: "60px",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }