var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-row",
    {
      attrs: {
        title: _vm.title,
        tooltip: _vm.tooltip,
        "line-number": _vm.lineNumber,
        "hide-line-number": _vm.hideLineNumber,
      },
    },
    [
      _c(
        "v-radio-group",
        {
          attrs: { value: _vm.$attrs.value, row: "", dense: "" },
          on: { change: _vm.onRadioValueChange },
        },
        _vm._l(_vm.items, function ({ value, label }) {
          return _c("v-radio", {
            key: value,
            attrs: { value: value, label: label },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }