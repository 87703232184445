<template>
    <div id="resources-page">
        <ContextTitle :passedActions="titleBarActions" title=" OAT Resources">
            <!-- <div slot="append-buttons">
                <env-selector-dropdown v-model="env"/>
            </div> -->
        </ContextTitle>
        <v-container fluid>
            <v-card width="100%" class="pa-3">
                <v-row class="mt-3">
                    <v-col md="8" class="pt-0 pb-0">
                        <store-search-new
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="onLocationChange"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 mt-5">
                    <v-col md="3" class="pr-3 pl-1">
                        <v-text-field
                            v-model="searchString"
                            attach
                            label="Search Resources"
                            clearable
                            @change="debounced_searchChange"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 mt-0" v-if="isWaypointsSelected">
                    <v-col md="3" class="pr-3 pl-1">
                        <v-select
                            v-model="languageCode"
                            :items="languageOptions"
                            item-value="id"
                            item-text="value"
                            attach
                            label="Language Code"
                            @change="languageChange"
                        />
                    </v-col>
                </v-row>
            </v-card>
            <v-row no-gutters>
                <v-col class="fill-height">
                    <v-row no-gutters v-if="mismatchedText.length > 0">
                        <v-col cols="12" class="pt-1 pb-1">
                            <v-banner
                                color="yellow darken-1 text--gray"
                                rounded
                                outlined
                            >
                                <v-icon color="yellow darken-2"
                                    >mdi-alert-outline
                                </v-icon>
                                {{ mismatchedText }}</v-banner
                            >
                        </v-col>
                    </v-row>
                    <v-row
                        no-gutters
                        justify="center"
                        class="overflow-y-auto mt-2"
                    >
                        <v-col md="12">
                            <v-card elevation="5">
                                <v-card-title v-if="!isWaypointsSelected">
                                    <h5 class="pt-3">
                                        Select a waypoint to start
                                    </h5></v-card-title
                                >
                                <v-card-text v-else-if="!loading" class="pa-1">
                                    <ResourceForm
                                        v-model="stageResources[languageCode]"
                                        :loading="loading"
                                        :mismatched="mismatched"
                                        :fields="filteredFields"
                                    />
                                </v-card-text>
                                <v-card-text v-else class="pa-2">
                                    <v-skeleton-loader
                                        v-for="i in 20"
                                        :key="i"
                                        type="list-item"
                                        class="ma-2 d-flex flex-column"
                                        elevation="2"
                                        width="100%"
                                        height="60px"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import EnvSelectorDropdown from "components/EnvSelectorDropdown";
import ContextTitle from "components/context_title";
import StoreSearchNew from "components/store_search_new";
import { mapGetters, mapActions } from "vuex";
import ResourceForm from "components/resource_form";
import _ from "lodash";
import clone from "clone";

const compareResources = (a, b) => {
    var result = {};
    for (const key in a) {
        if (Object.hasOwnProperty.call(a, key)) {
            const element = a[key];
            const x = b[key];
            if (
                typeof element == "object" &&
                Object.prototype.toString.call(element) !== "[object Array]" &&
                Object.keys(element).length > 0
            ) {
                const p = compareResources(element, x);
                if (p != undefined && Object.keys(p).length > 0)
                    result[key] = p;
            } else if (typeof element == "string") {
                if (element != x) {
                    result[key] = x;
                }
            }
        }
    }
    return result;
};

const countAllKeys = (a) => {
    var result = 0;
    for (const key in a) {
        if (Object.hasOwnProperty.call(a, key)) {
            const element = a[key];
            if (
                typeof element == "object" &&
                Object.prototype.toString.call(element) !== "[object Array]" &&
                Object.keys(element).length > 0
            ) {
                const p = countAllKeys(element);
                result += p;
            } else if (typeof element == "string") {
                result++;
            }
        }
    }
    return result;
};

const searchKeysAndValues = (obj, searchString) => {
    var result = {};
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            if (
                typeof element == "object" &&
                Object.prototype.toString.call(element) !== "[object Array]" &&
                Object.keys(element).length > 0
            ) {
                const p = searchKeysAndValues(element, searchString)
                if (p != undefined && Object.keys(p).length > 0)
                    result[key] = p;
                else if (key.toLowerCase().includes(searchString)) {
                    result[key] = element
                }
            } else if (typeof element == "string") {
                if (element.toLowerCase().includes(searchString) ||
                    key.toLowerCase().includes(searchString)) {
                    result[key] = element
                }
            }
        }
    }
    return result;
};
export default {
    name: "Resources",
    components: {
        ContextTitle,
        EnvSelectorDropdown,
        StoreSearchNew,
        ResourceForm,
    },
    data() {
        return {
            titleBarActions: [
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        this.save();
                    },
                },
                {
                    type: "duplicate",
                    display: "Publish To Prod",
                    run: () => {
                        this.publish();
                    },
                },
            ],
            env: "stage",
            searchString: "",
            airport: null,
            waypointId: undefined,
            languageCode: "en",
            allLanguages: {
                en: "English",
                ar: "Arabic",
                da: "Dutch",
                fi: "Finnish",
                fr: "French",
                ja: "Japanese",
                no: "Norwegian",
                sv: "Swedish",
                "zh-cn": "Chinese Simplified",
                "zh-tw": "Chinese Traditional",
                "es-mx": "Spanish (Mexico)",
                "zh-hans": "Chinese Simplified",
            },
            availableLanguages: {},
            stageResources: {},
        };
    },
    computed: {
        isWaypointsSelected() {
            return !!this.waypointId;
        },
        filteredFields() {
            if (this.searchString === undefined || this.searchString == null || this.searchString.length == 0 || this.stageResources[this.languageCode] == undefined) return this.stageResources[this.languageCode]

            return searchKeysAndValues(this.stageResources[this.languageCode], this.searchString.toLowerCase())
        },
        languageOptions() {
            return Object.entries(this.availableLanguages).map(([k, v]) => {
                return { id: k, value: v };
            });
        },
        mismatched() {
            if (
                this.resources.stage == undefined ||
                this.resources.stage[this.languageCode] == undefined
            )
                return {};
            if (
                this.resources.prod == undefined ||
                this.resources.prod[this.languageCode] == undefined
            )
                return {};
            return compareResources(
                this.resources.stage[this.languageCode],
                this.resources.prod[this.languageCode]
            );
        },
        loading() {
            return (
                this.stageResources === undefined ||
                this.stageResources[this.languageCode] === undefined
            );
        },
        mismatchedText() {
            if (Object.keys(this.mismatched).length == 0) return "";
            return `${countAllKeys(
                this.mismatched
            )} values in stage do not match production`;
        },
        ...mapGetters("ConfigStore", [
            "resources",
            "configStoreWaypointID",
            "configs",
        ]),
    },
    created() {
        this.debounced_searchChange = _.debounce(this.onSearchChange);
    },

    mounted() {
        this.waypointId = this.configStoreWaypointID;

        if (
            Object.keys(this.resources).length > 1 &&
            this.resources.stage != undefined
        )
            this.stageResources = clone(this.resources.stage);

        if (
            this.waypointId != undefined &&
            Object.keys(this.resources).length <= 1
        )
            this.onLocationChange(this.waypointId);
    },
    methods: {
        languageChange() {
            if (this.stageResources[this.languageCode] == undefined) {
                this.getResources({
                    storeWaypointID: this.waypointId,
                    languageCode: this.languageCode,
                });
            }
        },
        onSearchChange(str) {
            this.searchString = str;
        },
        filterFields(parent, obj, term) {
            if (
                typeof obj == "object" &&
                Object.prototype.toString.call(obj) !== "[object Array]" &&
                Object.keys(obj).length > 0
            ) {
                var result = {};
                for (const key in obj) {
                    const element = obj[key];
                    var filtered = this.filterFields(key, element, term);
                    if (filtered) result[key] = filtered;
                }
                return Object.keys(result).length > 0 ? result : undefined;
            } else if (typeof obj == "string") {
                if (
                    parent.toLowerCase().includes(term.toLowerCase()) ||
                    obj.toLowerCase().includes(term.toLowerCase())
                )
                    return obj;
                else return undefined;
            }
        },
        save() {
            this.saveResources({
                storeWaypointID: this.waypointId,
                languageCode: this.languageCode,
                resources: this.stageResources[this.languageCode],
            });
        },
        onLocationChange(waypointId) {
            this.stageResources = undefined;
            this.waypointId = waypointId;
            this.getResources({
                storeWaypointID: this.waypointId,
                languageCode: this.languageCode,
            });
            this.getConfigs(this.waypointId);
        },
        publish() {
            this.publishResources({
                storeWaypointID: this.waypointId,
                languageCode: this.languageCode,
            });
        },
        ...mapActions("ConfigStore", [
            "getResources",
            "saveResources",
            "publishResources",
            "getConfigs",
        ]),
    },
    watch: {
        waypointId(val) {
            this.onLocationChange(val);
        },
        resources(val) {
            this.stageResources = clone(this.resources.stage);
        },
        configs() {
            if (!this.configs || !this.configs.stage) return;
            var result = {};
            for (const key in this.configs.stage.tabletopLanguages) {
                if (
                    Object.hasOwnProperty.call(
                        this.configs.stage.tabletopLanguages,
                        key
                    )
                ) {
                    const element = this.configs.stage.tabletopLanguages[key];
                    result[element] = this.allLanguages[element];
                }
            }
            this.availableLanguages = result;
        },
    },
};
</script>

<style scoped>
</style>
