var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isString
        ? [
            _c(
              "field-row",
              {
                staticClass: "mt-1 ml-1 mr-1 r-tree text-md-body-1",
                style: _vm.computedStyle,
                attrs: {
                  "hide-line-number": "",
                  title: _vm.cleanTitle,
                  icon: _vm.icon,
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-textarea", {
                      staticClass: "pt-1",
                      attrs: {
                        light: "",
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        color: "#93BD20",
                        label: _vm.stageLabel,
                      },
                      model: {
                        value: _vm.fieldValue,
                        callback: function ($$v) {
                          _vm.fieldValue = $$v
                        },
                        expression: "fieldValue",
                      },
                    }),
                  ],
                  1
                ),
                _vm.mismatched
                  ? _c(
                      "v-row",
                      { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            light: "",
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                            readonly: "",
                            label: "Production",
                            color: "yellow darken-2",
                          },
                          model: {
                            value: _vm.mismatched,
                            callback: function ($$v) {
                              _vm.mismatched = $$v
                            },
                            expression: "mismatched",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.isNumber
        ? [
            _c(
              "field-row",
              {
                staticClass: "mt-1 ml-1 mr-1 r-tree text-md-body-1",
                style: _vm.computedStyle,
                attrs: {
                  "hide-line-number": "",
                  title: _vm.cleanTitle,
                  icon: _vm.icon,
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("v-text-field", {
                      staticClass: "pt-1",
                      attrs: {
                        light: "",
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        type: "number",
                        color: "#93BD20",
                        label: _vm.stageLabel,
                      },
                      model: {
                        value: _vm.fieldValue,
                        callback: function ($$v) {
                          _vm.fieldValue = _vm._n($$v)
                        },
                        expression: "fieldValue",
                      },
                    }),
                  ],
                  1
                ),
                _vm.mismatched
                  ? _c(
                      "v-row",
                      { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            light: "",
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                            type: "number",
                            readonly: "",
                            label: "Production",
                            color: "yellow darken-2",
                          },
                          model: {
                            value: _vm.mismatched,
                            callback: function ($$v) {
                              _vm.mismatched = _vm._n($$v)
                            },
                            expression: "mismatched",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.isBool
        ? [
            _c(
              "field-row",
              {
                staticClass: "mt-1 ml-1 mr-1 r-tree text-md-body-1",
                style: _vm.computedStyle,
                attrs: {
                  "hide-line-number": "",
                  title: _vm.cleanTitle,
                  icon: _vm.icon,
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("toggle", {
                      staticClass: "pt-1",
                      attrs: {
                        slider: true,
                        light: "",
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        color: "#93BD20",
                        label: _vm.stageLabel,
                      },
                      model: {
                        value: _vm.fieldValue,
                        callback: function ($$v) {
                          _vm.fieldValue = $$v
                        },
                        expression: "fieldValue",
                      },
                    }),
                  ],
                  1
                ),
                _vm.mismatched
                  ? _c(
                      "v-row",
                      { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                      [
                        _c("toggle", {
                          attrs: {
                            slider: true,
                            light: "",
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                            readonly: "",
                            label: "Production",
                            color: "yellow darken-2",
                          },
                          model: {
                            value: _vm.mismatched,
                            callback: function ($$v) {
                              _vm.mismatched = $$v
                            },
                            expression: "mismatched",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm.isArray
        ? [
            _c(
              "v-expansion-panels",
              {
                staticClass: "mb-1",
                model: {
                  value: _vm.panel,
                  callback: function ($$v) {
                    _vm.panel = $$v
                  },
                  expression: "panel",
                },
              },
              [
                _c(
                  "v-expansion-panel",
                  { attrs: { readonly: _vm.depth == 0 } },
                  [
                    _vm.depth > 0
                      ? _c(
                          "v-expansion-panel-header",
                          { staticClass: "grey lighten-4" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _vm.mismatched !== undefined &&
                                Object.keys(_vm.mismatched).length > 0
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "yellow darken-2" } },
                                      [_vm._v("mdi-alert-outline")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "text-md-body-1 ml-1" },
                                  [_vm._v(" " + _vm._s(_vm.cleanTitle) + " ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-expansion-panel-content",
                      { staticClass: "pa-0 mb-3" },
                      _vm._l(_vm.childKeys, function (k) {
                        return _c("ResourceTree", {
                          key: k,
                          attrs: {
                            title: k,
                            fields: _vm.fields[k],
                            depth: _vm.depth + 1,
                            mismatched: _vm.safeMismatched(k),
                          },
                          on: { onInput: _vm.onInput },
                          model: {
                            value: _vm.value[k],
                            callback: function ($$v) {
                              _vm.$set(_vm.value, k, $$v)
                            },
                            expression: "value[k]",
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.isObject
        ? [
            _c(
              "v-expansion-panels",
              {
                staticClass: "mb-1",
                model: {
                  value: _vm.panel,
                  callback: function ($$v) {
                    _vm.panel = $$v
                  },
                  expression: "panel",
                },
              },
              [
                _c(
                  "v-expansion-panel",
                  { attrs: { readonly: _vm.depth == 0 } },
                  [
                    _vm.depth > 0
                      ? _c(
                          "v-expansion-panel-header",
                          { staticClass: "grey lighten-4" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _vm.mismatched !== undefined &&
                                Object.keys(_vm.mismatched).length > 0
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "yellow darken-2" } },
                                      [_vm._v("mdi-alert-outline")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "text-md-body-1 ml-1" },
                                  [_vm._v(" " + _vm._s(_vm.cleanTitle) + " ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-expansion-panel-content",
                      { staticClass: "pa-0 mb-3" },
                      _vm._l(_vm.childKeys, function (k) {
                        return _c("ResourceTree", {
                          key: k,
                          attrs: {
                            title: k,
                            fields: _vm.fields[k],
                            depth: _vm.depth + 1,
                            mismatched: _vm.safeMismatched(k),
                          },
                          on: { onInput: _vm.onInput },
                          model: {
                            value: _vm.value[k],
                            callback: function ($$v) {
                              _vm.$set(_vm.value, k, $$v)
                            },
                            expression: "value[k]",
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }