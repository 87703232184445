<template>
  <v-select
      v-bind="$attrs"
      v-on="$listeners"
      :items="items"
      label="Environment"
      attach
      style="padding-left: 5px;"
  >
      <template v-slot:selection="{item: {text}}">
            {{ text }}
      </template>
  </v-select>
</template>

<script>
export default {
    name: "EnvSelectorDropdown",
    data() {
        return {
            items: [{text: 'Stage', value: 'stage'}, {text: 'Production', value: 'prod'}],
        };

    }
}
</script>

<style scoped>

</style>
