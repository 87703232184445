<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                mdi-help-circle
            </v-icon>
        </template>
        <span>
            {{label}}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: "text_tooltip",
    props: {
        label: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>

</style>
