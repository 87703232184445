<template>
    <div>
        <template v-if="isString">
            <field-row
                :style="computedStyle"
                hide-line-number
                :title="cleanTitle"
                class="mt-1 ml-1 mr-1 r-tree text-md-body-1"
                :icon="icon"
            >
                <v-row no-gutters>
                    <v-textarea class="pt-1"
                        v-model="fieldValue"
                        light
                        dense
                        hide-details
                        outlined
                        color="#93BD20"
                        :label="stageLabel"
                    />
                </v-row>
                <v-row no-gutters v-if="mismatched" class="mt-2">
                    <v-textarea
                    v-model="mismatched"
                    light
                    dense
                    hide-details
                    outlined
                    readonly
                    label="Production"
                    color="yellow darken-2"
                    />
                </v-row>
            </field-row>
        </template>
        <template v-else-if="isNumber">
            <field-row
                :style="computedStyle"
                hide-line-number
                :title="cleanTitle"
                class="mt-1 ml-1 mr-1 r-tree text-md-body-1"
                :icon="icon"
            >
                <v-row no-gutters>
                    <v-text-field class="pt-1"
                        v-model.number="fieldValue"
                        light
                        dense
                        hide-details
                        outlined
                        type="number"
                        color="#93BD20"
                        :label="stageLabel"
                    />
                </v-row>
                <v-row no-gutters v-if="mismatched" class="mt-2">
                    <v-text-field
                    v-model.number="mismatched"
                    light
                    dense
                    hide-details
                    outlined
                    type="number"
                    readonly
                    label="Production"
                    color="yellow darken-2"
                    />
                </v-row>
            </field-row>
        </template>
        <template v-else-if="isBool">
            <field-row
                :style="computedStyle"
                hide-line-number
                :title="cleanTitle"
                class="mt-1 ml-1 mr-1 r-tree text-md-body-1"
                :icon="icon"
            >
                <v-row no-gutters>
                    <toggle class="pt-1"
                        v-model="fieldValue"
                        :slider="true"
                        light
                        dense
                        hide-details
                        outlined
                        color="#93BD20"
                        :label="stageLabel"
                    />
                </v-row>
                <v-row no-gutters v-if="mismatched" class="mt-2">
                    <toggle :slider="true"
                    v-model="mismatched"
                    light
                    dense
                    hide-details
                    outlined
                    readonly
                    label="Production"
                    color="yellow darken-2"
                    />
                </v-row>
            </field-row>
        </template>
        <template v-else-if="isArray">
            <v-expansion-panels class="mb-1" v-model="panel">
                <v-expansion-panel :readonly="depth == 0">
                    <v-expansion-panel-header class="grey lighten-4" v-if="depth > 0">
                        <v-row no-gutters>
                            <v-icon
                                v-if="mismatched !== undefined && Object.keys(mismatched).length > 0"
                                color="yellow darken-2"
                                >mdi-alert-outline</v-icon
                            >
                            <div class="text-md-body-1 ml-1">
                                {{ cleanTitle }}
                            </div>
                        </v-row></v-expansion-panel-header
                    >
                    <v-expansion-panel-content class="pa-0 mb-3">
                        <ResourceTree
                            v-for="k in childKeys"
                            :key="k"
                            :title="k"
                            :fields="fields[k]"
                            v-model="value[k]"
                            :depth="depth + 1"
                            :mismatched="safeMismatched(k)"
                            @onInput="onInput"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
        <template v-else-if="isObject">
            <v-expansion-panels class="mb-1" v-model="panel">
                <v-expansion-panel :readonly="depth == 0">
                    <v-expansion-panel-header class="grey lighten-4" v-if="depth > 0">
                        <v-row no-gutters>
                            <v-icon
                                v-if="mismatched !== undefined && Object.keys(mismatched).length > 0"
                                color="yellow darken-2"
                                >mdi-alert-outline</v-icon
                            >
                            <div class="text-md-body-1 ml-1">
                                {{ cleanTitle }}
                            </div>
                        </v-row></v-expansion-panel-header
                    >
                    <v-expansion-panel-content class="pa-0 mb-3">
                        <ResourceTree
                            v-for="k in childKeys"
                            :key="k"
                            :title="k"
                            :fields="fields[k]"
                            v-model="value[k]"
                            :depth="depth + 1"
                            :mismatched="safeMismatched(k)"
                            @onInput="onInput"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </div>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
import toggle from "components/cleverly/Toggle";
import _ from "lodash";

export default {
    name: "ResourceTree",
    data() {
        return {};
    },
    props: ["title", "value", "depth", "mismatched", "fields"],
    components: {
        FieldRow,
        toggle
    },
    computed: {
        fieldValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("onInput", { key: this.title, value: val });
            },
        },
        isString() {
            return typeof this.value === "string";
        },
        isNumber() {
            return typeof this.value === "number";
        },
        isBool() {
            return typeof this.value === "boolean";
        },
        isArray() {
            return typeof this.value === "object" &&
                Object.prototype.toString.call(this.value) ===
                    "[object Array]";
        },
        isObject() {
            return (
                typeof this.value == "object" &&
                Object.prototype.toString.call(this.value) !==
                    "[object Array]" &&
                Object.keys(this.value).length > 0
            );
        },

        cleanTitle() {
            return this.title
                .split("_")
                .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
                .join(" ");
        },
        childKeys() {
            if (this.fields === undefined || this.fields === null) return []
            return _.orderBy(
                Object.keys(this.fields),
                [(key) => key.toLowerCase()],
                ["asc"]
            );
        },
        errors() {
            if (this.mismatched == undefined) return [];
            return [this.mismatched];
        },
        computedStyle() {
            var height = "60px;"
            if (this.mismatched) height = "120px;"
            return {'height':height}
        },
        stageLabel() {
            if (this.mismatched == undefined) return ""
            return "Stage"
        },
        panel: {
            get() {
                return this.depth == 0 ? 0 : undefined;
            },
            set(val) {},
        },
        icon() {
            if (this.mismatched !== undefined && Object.keys(this.mismatched).length > 0)
                return 'mdi-alert-outline'
            return undefined
        }
    },
    methods: {
        safeMismatched(key) {
            if (this.mismatched == undefined) return undefined;
            return this.mismatched[key];
        },
        onInput(val) {
            var result = { ...this.value };
            result[val.key] = val.value;
            this.$emit("onInput", { key: this.title, value: result });
        },
    },
};
</script>

<style>

.v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
</style>