<template>
    <ResourceTree
        title=""
        v-model="value"
        :mismatched="mismatched"
        :fields="fields"
        v-if="!loading"
        @onInput="onInput"
        depth="0"
    />
</template>

<script>
import _ from "lodash";
import RadioGroupRow from "components/create_store/fields/RadioGroupRow";
import FieldRow from "components/create_store/fields/FieldRow";
import TextTooltip from "components/text_tooltip";
import ResourceTree from "components/resource_tree";


export default {
    name: "ResourcesForm",
    data() {
        return {};
    },
    props: {
        value: {
            type: Object,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        mismatched: {
            type: Object,
        },
        fields: {
            type: Object
        }
    },
    components: {
        RadioGroupRow,
        FieldRow,
        TextTooltip,
        ResourceTree,
    },
    computed: {
        disabled() {
            return this.env == "prod";
        },

    },

    methods: {
        textFieldColorForField(field) {
            return "#93BD20";
        },
        onInput(val) {
            this.$emit("input", {
                ...this.value,
                ...val.value,
            });
        },
    },
    watch: {
        waypointId(value) {},
    },
};
</script>

<style lang="css" scoped>
.my-border {
    border: 2px solid black;
}
</style>
