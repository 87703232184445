var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("ResourceTree", {
        attrs: {
          title: "",
          mismatched: _vm.mismatched,
          fields: _vm.fields,
          depth: "0",
        },
        on: { onInput: _vm.onInput },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }