var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          staticStyle: { "padding-left": "5px" },
          attrs: { items: _vm.items, label: "Environment", attach: "" },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function ({ item: { text } }) {
                return [_vm._v(" " + _vm._s(text) + " ")]
              },
            },
          ]),
        },
        "v-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }